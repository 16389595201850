import { Box, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RegistrationSchema } from '../../../utils/schema'
import ControlInput from '../formComponents/ControlInput'
import ConfirmButton from '../formComponents/ConfirmButton'
import FormHead from '../formComponents/formHead'
import useAuthAPI from '../../../api/api_hooks/auth/useAuthAPI'
import { Navigate } from 'react-router-dom'
import ControlPassword from '../formComponents/ControlPassword'
import { useGetPrams } from '../../../utils/getPrams'
import SpecialtiesDropdown from '../../specialtiesDropdown'

type Inputs = {
  companyName: string
  contactName: string
  phoneNumber: string
  specialtyId: number
  email: string
  password: string
  confirmPassword: string
}

export default function RegisterForm() {
  const { useRegistration } = useAuthAPI()
  const token = useGetPrams('invasionToken')
  const post = useRegistration(token ? token : '')
  const toast = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(RegistrationSchema),
  })
  const onSubmit: SubmitHandler<Inputs> = (formData) => {
    post.mutateAsync(formData as unknown as void).catch((e) =>
      toast({
        title: e.response.data.message,
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    )
  }

  if (post.isSuccess) return <Navigate to="/confirm-email" replace />

  const isFormFilled: boolean = !!Object.keys(errors).length

  return (
    <Box mt={116} minW={270}>
      <FormHead title={'Create your account'} />

      <Box mt={'20px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlInput
            register={{ ...register('companyName') }}
            label="Company Name"
            placeholder="Company Name"
            isInvalid={!!errors.companyName}
            errorMessage={errors.companyName && errors.companyName.message}
          />
          <ControlInput
            register={{ ...register('contactName') }}
            label="Contact Name"
            placeholder="Contact Name"
            isInvalid={!!errors.contactName}
            errorMessage={errors.contactName && errors.contactName.message}
          />
          <ControlInput
            register={{ ...register('phoneNumber') }}
            label="Phone Number"
            placeholder="Phone Number"
            isInvalid={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber && errors.phoneNumber.message}
          />
          <SpecialtiesDropdown
            value={watch('specialtyId') || ''}
            onChange={(value) => setValue('specialtyId', value)}
            errorMessage={errors.specialtyId?.message}
          />
          <ControlInput
            register={{ ...register('email') }}
            label="Email"
            placeholder="Email"
            isInvalid={!!errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <ControlPassword
            register={{ ...register('password') }}
            label="Create password"
            placeholder="Create password"
            isInvalid={!!errors.password}
            errorMessage={errors.password && errors.password.message}
          />
          <ControlPassword
            register={{ ...register('confirmPassword') }}
            label="Confirm password"
            placeholder="Confirm password"
            isInvalid={!!errors.confirmPassword}
            errorMessage={
              errors.confirmPassword && errors.confirmPassword.message
            }
          />
          <ConfirmButton text="Next" isDisabled={isFormFilled} />
        </form>
      </Box>
    </Box>
  )
}
