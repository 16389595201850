import {
  Avatar,
  Box,
  FormControl,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react'
import Camera from '../../assets/svg/camera'
import { UseFormRegisterReturn } from 'react-hook-form'

interface IAvatarBox {
  register?: UseFormRegisterReturn
  avatar?: Blob | string
  isInvalid?: boolean
  errorMessage?: any
}

export default function AvatarBox({
  errorMessage,
  isInvalid,
  register,
  avatar,
}: IAvatarBox) {
  const BASE_URL =
    process.env.REACT_APP_API_DOMAIN || 'https://api-staging.careflai.com'
  const avatarSrc =
    typeof avatar === 'string'
      ? `${BASE_URL}/${avatar}`
      : avatar && URL.createObjectURL(avatar)

  return (
    <FormControl isInvalid={isInvalid}>
      <Box as="span" position={'relative'} display={'inline-flex'}>
        <Input
          w={'100%'}
          h={'100%'}
          position={'absolute'}
          zIndex={1}
          cursor={'pointer'}
          borderRadius={100}
          opacity={0}
          type="file"
          name="myImage"
          accept=".png, .jpg"
          {...register}
        />
        <Avatar
          size="2xl"
          name="O K"
          src={avatarSrc}
          border={'4px solid white'}
        />
        <Box
          as="span"
          position={'absolute'}
          p={2}
          background={'#344054'}
          right={0}
          bottom={0}
          borderRadius={50}
          border={'2px solid white'}
        >
          <Camera />
        </Box>
      </Box>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
