import axios from 'axios'

const BASE_URL =
  process.env.REACT_APP_API_DOMAIN || 'https://api-staging.careflai.com'

const axiosClient = axios.create({
  baseURL: `${BASE_URL}/api/`,
})

export const publicAxios = axios.create({
  baseURL: `${BASE_URL}/api/`,
  headers: {
    'Content-type': 'application/json',
  },
})

export default axiosClient
