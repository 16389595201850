import React, { useEffect, useState, useCallback } from 'react'
import {
  FormControl,
  FormErrorMessage,
  Input,
  Box,
  List,
  ListItem,
  useOutsideClick,
  Text,
  FormControlProps,
} from '@chakra-ui/react'
import { useGetSpecialties } from '../../api/api_hooks/specialties'
import { ISpecialty } from '../../types/specialties'

interface SpecialtiesDropdownProps extends Omit<FormControlProps, 'onChange'> {
  value: string | number
  onChange: (value: number) => void
  errorMessage?: string
}

const SpecialtiesDropdown: React.FC<SpecialtiesDropdownProps> = ({
  value,
  onChange,
  errorMessage,
  ...props
}) => {
  const { result: specialties, isError, error, isSuccess } = useGetSpecialties()
  const [inputValue, setInputValue] = useState<string>('')
  const [filteredSpecialties, setFilteredSpecialties] = useState<ISpecialty[]>(
    []
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const ref = React.useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  })

  useEffect(() => {
    if (!isSuccess) return
    setFilteredSpecialties(specialties)
  }, [isSuccess, specialties])

  useEffect(() => {
    const selectedSpecialty = specialties.find(
      (s: ISpecialty) => s.id === Number(value)
    )
    if (selectedSpecialty) {
      setInputValue(selectedSpecialty.name)
    }
  }, [value, specialties])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setInputValue(value)
      setIsOpen(true)

      const filtered = value
        ? specialties.filter((specialty) =>
            specialty.name.toLowerCase().includes(value.toLowerCase())
          )
        : specialties
      setFilteredSpecialties(filtered)
    },
    [specialties]
  )

  const handleFocusChange = (isFocused: boolean) => {
    const delay = isFocused ? 0 : 300
    // Delay the closing of the dropdown to allow the user to click on the dropdown
    setTimeout(() => {
      setIsOpen(isFocused)
    }, delay)
  }

  const handleSelectSpecialty = (specialty: ISpecialty) => {
    setInputValue(specialty.name)
    onChange(specialty.id)
    setIsOpen(false)
  }

  const displayError = errorMessage || (isError ? error?.message : '')

  return (
    <FormControl
      {...props}
      isInvalid={!!errorMessage}
      ref={ref}
      color={'brand.lightGray'}
      mt={4}
    >
      <Text>Specialty</Text>
      <div
        onFocus={() => handleFocusChange(true)}
        onBlur={() => handleFocusChange(false)}
      >
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search for a specialty"
          isDisabled={!isSuccess}
          _placeholder={{ color: 'brand.lightGray' }}
          mt={1}
          backgroundColor={'white'}
        />
        {isOpen && filteredSpecialties.length > 0 && (
          <Box
            position="absolute"
            mt={1}
            w="100%"
            bg="white"
            boxShadow="md"
            borderRadius="md"
            zIndex={1000}
            maxH="200px"
            overflowY="auto"
          >
            <List>
              {filteredSpecialties.map((specialty) => (
                <ListItem
                  key={specialty.id}
                  px={4}
                  py={2}
                  cursor="pointer"
                  _hover={{ bg: 'gray.100' }}
                  onClick={() => handleSelectSpecialty(specialty)}
                >
                  {specialty.name}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </div>
      {displayError && <FormErrorMessage>{displayError}</FormErrorMessage>}
    </FormControl>
  )
}

export default SpecialtiesDropdown
