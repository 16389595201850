import ControlInput from '../formComponents/ControlInput'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MyProfile } from '../../../utils/schema'
import { Box, Button, Center, Flex, Text, useToast } from '@chakra-ui/react'
import AvatarBox from '../../avatar'
import { useUpdateProfile } from '../../../api/api_hooks/user/useUpdateProfile'
import { useEffect } from 'react'
import { useMyProfile } from '../../../api/api_hooks/user/myProfile'
import SpecialtiesDropdown from '../../specialtiesDropdown'

type Inputs = {
  companyName: string
  contactName: string
  phoneNumber: string
  email: string
  specialtyId: number
  avatar?: any
}

export default function ProfileForm() {
  const toast = useToast()
  const { data: userData, refetch, isError } = useMyProfile()
  const { mutate, isSuccess, isError: updatePrifile } = useUpdateProfile()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(MyProfile),
  })
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const file = data.avatar[0]
    const formData = new FormData()
    formData.append('companyName', data.companyName)
    formData.append('contactName', data.contactName)
    formData.append('email', data.email)
    formData.append('phoneNumber', data.phoneNumber)
    formData.append('specialtyId', data.specialtyId.toString())
    formData.append('file', file)

    mutate(formData as unknown as void)
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: `Data update - success`,
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (userData) {
      setValue('companyName', userData.companyName)
      setValue('contactName', userData.contactName)
      setValue('email', userData.email)
      setValue('phoneNumber', userData.phoneNumber)
      setValue('specialtyId', userData.specialtyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  if (isError || updatePrifile) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  const watchAvatar = watch('avatar')

  return (
    <Box mt={'60px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={5}>
          <Box>
            <AvatarBox
              avatar={watchAvatar?.[0] || userData?.avatarFilePath}
              register={{ ...register('avatar') }}
              isInvalid={!!errors.avatar}
              errorMessage={errors.avatar && errors.avatar.message}
            />
          </Box>
          <Center>
            <Text fontWeight={600} fontSize={'xl'} pb={10}>
              {userData?.contactName}
            </Text>
          </Center>
        </Flex>
        <ControlInput
          register={{ ...register('companyName') }}
          label="Your company name"
          placeholder="Company Name"
          isInvalid={!!errors.companyName}
          errorMessage={errors.companyName && errors.companyName.message}
        />
        <ControlInput
          register={{ ...register('contactName') }}
          label="Contact Name"
          placeholder="Contact Name"
          isInvalid={!!errors.contactName}
          errorMessage={errors.contactName && errors.contactName.message}
        />
        <ControlInput
          register={{ ...register('phoneNumber') }}
          label="Phone Number"
          placeholder="Phone Number"
          isInvalid={!!errors.phoneNumber}
          errorMessage={errors.phoneNumber && errors.phoneNumber.message}
        />
        <ControlInput
          register={{ ...register('email') }}
          label="Email"
          placeholder="Email"
          isInvalid={!!errors.email}
          errorMessage={errors.email && errors.email.message}
        />
        <SpecialtiesDropdown
          value={watch('specialtyId')}
          onChange={(value) => setValue('specialtyId', value)}
          errorMessage={errors.specialtyId?.message}
        />
        <Box display={'flex'} gap={4} mt={5} justifyContent="flex-end">
          <Button
            background="brand.purple"
            type="submit"
            // onClick={() => reset()}
            color={'white'}
            p={'0px 40px'}
          >
            Update
          </Button>
          <Button onClick={() => reset()} variant="outline" p={'0px 40px'}>
            Reset
          </Button>
        </Box>
      </form>
    </Box>
  )
}
